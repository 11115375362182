import { IPhieuNhapKhoResponseBase } from '@/core/interfaces/ApiResponses';

const usePhieuNhap = () => {
  const goToEditPhieuNhap = ({
    id, id_hop_dong, id_kho
  }: IPhieuNhapKhoResponseBase) => {
    if (id_hop_dong) {
      return {
        name: 'chinh-sua-phieu-nhap-kho-theo-hop-dong',
        params: {
          idHopDong: id_hop_dong,
          idKho: id_kho,
          idPhieuNhap: id,
        },
      };
    }

    return {
      name: 'chinh-sua-phieu-nhap-kho-le',
      params: {
        idKho: id_kho,
        idPhieuNhap: id,
      },
    };
  }

  const editable = (phieuNhapData: IPhieuNhapKhoResponseBase) => {
    if (phieuNhapData) return phieuNhapData.is_approved === 0 && !phieuNhapData.ngay_hoan_thanh;

    return false;
  }

  return {
    editable,
    goToEditPhieuNhap,
  };
}

export default usePhieuNhap;
