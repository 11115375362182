
import { computed, defineComponent, ref } from "vue";
import { HopDongService } from "@/core/services/HopDong.service";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { IBienBanResponseBase, IHopDongResponseBase, IPhieuNhapKhoResponseBase, IVatTuHopDongResponseBase } from "@/core/interfaces/ApiResponses";
import { getErrorMsg, swalNotification } from "@/core/helpers/utils";
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { useAccount } from '@/core/hooks/use-account';
import { useLoading } from '@/core/hooks/use-loading';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import moment from "moment";
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import AddVatTuItems from '@/components/add-vat-tu-items/AddVatTuItems.vue'
import HopDongVatTuTable from './hop-dong-vat-tu-table/HopDongVatTuTable.vue';
import BienBanKiemNghiemTable from './bien-ban-kiem-nghiem-table/BienBanKiemNghiemTable.vue';
import BienBanKiemNghiemPrintModal from '@/components/shared/bien-ban-kiem-nghiem-print-modal/BienBanKiemNghiemPrintModal.vue';
import PhieuNhapKhoTable from '@/views/crafted/pages/hop-dong/phieu-nhap-kho-table/PhieuNhapKhoTable.vue'
import DivCol from '@/layout/_shared/DivCol.vue';
import PhieuNhapPrintModal from '@/components/shared/phieu-nhap-print-modal/PhieuNhapPrintModal.vue';
import LabelCustom from '@/components/shared/label-custom/LabelCustom.vue';
import useHopDong from "@/core/hooks/use-hop-dong";
import { PhieuNhapKhoService } from '@/core/services/PhieuNhapKho.service';

export default defineComponent({
  name: "contract-detail",

  components: {LabelCustom, PageRowLayout, AddVatTuItems, ButtonCustom, HopDongVatTuTable, BienBanKiemNghiemTable, PhieuNhapKhoTable, DivCol, PhieuNhapPrintModal, BienBanKiemNghiemPrintModal },

  setup: function () {
    const { goBack, push, id } = useRouterCustom();
    const { startLoading, endLoading, isLoading } = useLoading();
    const { currentUserData } = useAccount();
    const { ButtonsType, ButtonTypeColors } = useButtonCustom();
    const { loaiHopDong } = useHopDong();

    const hopDong = ref<IHopDongResponseBase | null>(null);
    const hopDongVatTuItems = ref<IVatTuHopDongResponseBase[]>([]);

    return {
      formatDate: computed(() => moment),
      goBack, push,
      hopDongId: id, loaiHopDong,
      hopDong,
      hopDongVatTuItems,
      currentUserData,
      startLoading, endLoading, isLoading,
      ButtonsType, ButtonTypeColors,
    };
  },

  async mounted() {
    setCurrentPageTitle('Hợp đồng');

    await this.fetchData();

    this.idPhieuNhapDetail = +this.$route.query['chi-tiet-phieu-nhap'];

    if (this.idPhieuNhapDetail) {
      const { data: { data } } = await PhieuNhapKhoService.get(this.idPhieuNhapDetail);

      this.openingPhieuNhap = data;

      this.onPhieuNhapDetailButtonClicked({
        phieuNhapKhoData: this.openingPhieuNhap
      });
    }
  },

  data() {
    return {
      isAddVatTuModalDisplay: false,
      isPhieuNhapModalDisplay: false,
      isBienBanModalDisplay: false,
      idPhieuNhapDetail: null as null | number,
      openingPhieuNhap: null as IPhieuNhapKhoResponseBase | null,
      openingBienBan: null as IBienBanResponseBase | null,
    }
  },

  computed: {
    isHoanThanh() {
      if (this.hopDong && Object.keys(this.hopDong.remainImport).length === 0) {
        return false;
      }
      if (!this.hopDong) {
        return false;
      } else {
        let result = true;
        for (let i = 0; i < this.hopDong.remainImport.length; i++) {
          if (this.hopDong.remainImport[i].so_luong > 0) {
            result = false;
            break;
          }
        }

        return result
      }
    },
    isHopDongHetHan() {
      if (!this.hopDong?.ngay_het_han) return true;

      return moment(new Date(this.hopDong.ngay_het_han)).isBefore(new Date());
    },
    loaiHopDongDisplay() {
      return this.loaiHopDong(this.hopDong);
    }
  },

  methods: {
    onPhieuNhapDetailButtonClicked({
      phieuNhapKhoData,
    }: {
      phieuNhapKhoData: IPhieuNhapKhoResponseBase;
    }) {
			this.isPhieuNhapModalDisplay = true;
			this.openingPhieuNhap = phieuNhapKhoData;
		},

    onClosePrintModal() {
			this.isPhieuNhapModalDisplay = false;
			this.openingPhieuNhap = null;
		},

    onBienBanDetailButtonClicked({ bienBan }: { bienBan: IBienBanResponseBase }) {
      this.isBienBanModalDisplay = true;
      this.openingBienBan = bienBan;
    },

    onCloseBienBanPrintModal() {
			this.isBienBanModalDisplay = false;
			this.openingBienBan = null;
		},

    async fetchData() {
      try {
        this.startLoading();
        const { data: { data: res } } = await HopDongService.fetchContractDetail(this.hopDongId);

        this.hopDong = res;

        this.hopDongVatTuItems = [];

        if (res.hop_dong_vat_tu) {
          res.hop_dong_vat_tu.forEach(item => {
            this.hopDongVatTuItems.push({
              ...item,
              so_luong_trong_hop_dong: item.pivot.so_luong,
              don_gia: item.pivot.so_tien, // TODO: update thanh don gia cua vat tu torng hop dong
            })
          });
        }
        this.endLoading();
      } catch (err) {
        this.endLoading();

				await swalNotification(
					getErrorMsg(err, 'Có lỗi xảy ra khi lấy thông tin hợp đồng'),
					'error',
				);
			}
    },

    onAddVatTuButtonClicked() {
      this.isAddVatTuModalDisplay = true;
    },

    async onAddedVatTuItems() {
      this.isAddVatTuModalDisplay = false;
      await this.fetchData();
    },

    onAddPhieuNhapButtonClickHandler() {
      this.push(`/quan-ly/hop-dong/${this.hopDongId}/tao-phieu-nhap-kho`);
    },

    onAddBBKNButtonClickHandler() {
      this.push(`/quan-ly/hop-dong/${this.hopDongId}/tao-bbkn`);
    },
  }
});
