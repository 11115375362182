import { IHopDongResponseBase } from '@/core/interfaces/ApiResponses';

const useHopDong = () => {
  const loaiHopDong = (hopDong: IHopDongResponseBase | null) => {
    if (!hopDong) return '';

    if (hopDong.loai_hop_dong === 1) return 'Hợp đồng mua bán';

    return 'Hợp đồng điều chỉnh';
  };

  return {
    loaiHopDong,
  };
}

export default useHopDong;
