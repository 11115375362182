
import {defineComponent, PropType} from 'vue';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {IPhieuNhapKhoResponseBase} from '@/core/interfaces/ApiResponses';
import {formatCurrencyAndQuantity} from '@/core/helpers/utils';

import DivCol from '../../../layout/_shared/DivCol.vue';
import DivRow from '../../../layout/_shared/DivRow.vue';
import ToolService from '../../../core/services/Tool.service';
import SignatureCol from '../../../layout/_shared/SignatureCol.vue';
import ButtonCustom from '../buttons/ButtonCustom.vue';
import {useButtonCustom} from '@/core/hooks/use-button-custom';

export default defineComponent({
  name: 'phieu-nhap-print-modal',

  components: {DivCol, DivRow, SignatureCol, ButtonCustom},

  setup() {
    const {moment} = useDateTime();

    const {ButtonsType, ButtonTypeColors} = useButtonCustom();

    return {
      moment,
      ButtonsType, ButtonTypeColors,
    }
  },

  emits: ['on-close-modal'],

  props: {
    title: {
      type: String,
      default: 'Phiếu nhập kho'
    },
    phieuNhapDetail: {
      type: Object as PropType<IPhieuNhapKhoResponseBase | null>,
      default: () => null,
    },
    tenDonVi: {
      type: String,
      default: '',
    },
    isModalOpen: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      isOpen: this.isModalOpen,
      tongTienInText: '',
    }
  },

  watch: {
    async isModalOpen(value) {
      this.isOpen = value;

      let total = 0;

      console.log(this.phieuNhapDetail)
      if (this.phieuNhapDetail) {
        this.phieuNhapDetail.vat_tu_nhap_kho.forEach(({pivot: {don_gia, so_luong}}) => {
          total += +so_luong * +don_gia
        });

        const {
          data: {data: totalPriceOfWarehouseInText},
        } = await ToolService.convertPriceToText(total);

        this.tongTienInText = totalPriceOfWarehouseInText;
      }
    },
  },

  computed: {
    ngayNhapKhoDisplay() {
      // TODO: đang để ngày tạo của phiếu nhập
      const date = new Date(this.phieuNhapDetail.created_at);

      return `Ngày ${date.getDate()} tháng ${date.getMonth() + 1} năm ${date.getFullYear()}`;
    },

    totalPrice() {
      let result = 0;

      if (this.phieuNhapDetail) {
        this.phieuNhapDetail.vat_tu_nhap_kho.forEach(({pivot: {don_gia, so_luong}}) => {
          result += +so_luong * +don_gia
        });
      }

      return result;
    }
  },

  methods: {
    formatCurrencyAndQuantity,

    onClose() {
      this.$emit('on-close-modal');
    },

    onPrintingButtonClicked(): void {
      (this as any).$htmlToPaper('phieu-nhap-kho-printing-modal')
    },
  }
})
