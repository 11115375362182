
import {defineComponent, PropType} from 'vue';
import {useDateTime} from '@/core/hooks/use-date-time-hook';
import {
  IBienBanResponseBase,
  IHopDongResponseBase,
  IVatTuBienBanKiemNghiemResponseBase
} from '@/core/interfaces/ApiResponses';
import {formatCurrencyAndQuantity} from '@/core/helpers/utils';

import DivCol from '../../../layout/_shared/DivCol.vue';
import DivRow from '../../../layout/_shared/DivRow.vue';
import ButtonCustom from '../buttons/ButtonCustom.vue';
import {useButtonCustom} from '@/core/hooks/use-button-custom';

export default defineComponent({
  name: 'bien-ban-print-modal',

  components: {ButtonCustom, DivCol, DivRow},

  emits: ['on-close-modal'],

  setup() {
    const {moment} = useDateTime();
    const {ButtonsType} = useButtonCustom();

    return {
      moment,
      ButtonsType,
    }
  },

  props: {
    title: {
      type: String,
      default: 'Biên bản kiểm nghiệm'
    },

    bienBanData: {
      type: Object as PropType<IBienBanResponseBase | null>,
      default: () => null,
    },

    hopDongData: {
      type: Object as PropType<IHopDongResponseBase | null>,
      default: () => null,
    },

    tenDonVi: {
      type: String,
      default: '',
    },

    isModalOpen: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      isOpen: this.isModalOpen,
    }
  },

  watch: {
    isModalOpen(value) {
      this.isOpen = value;
    },
  },

  computed: {
    ngayBienBanDisplay() {
      if (this.bienBanData?.ngay_bien_ban) {
        const date = new Date(this.bienBanData?.ngay_bien_ban);
        return `Ngày ${date.getDate()} tháng ${date.getMonth() + 1} năm ${date.getFullYear()}`;
      }

      return '.........................'
    },

    tenGiamDocDisplay() {
      return this.bienBanData.ten_giam_doc ? this.bienBanData.ten_giam_doc : '.........................';
    },

    thanhPhanItems() {
      if (!this.bienBanData?.thanh_phan_hoi_dong) return [];

      return JSON.parse(this.bienBanData?.thanh_phan_hoi_dong);
    },

    ngayHopDongDisplay() {
      if (this.hopDongData?.ngay_ky) {
        return this.moment(this.hopDongData?.ngay_ky.toString()).format('YYYY-MM-DD');
      }

      return '.......................';
    }
  },

  methods: {
    donGiaVatTuDisplay(vatTu: IVatTuBienBanKiemNghiemResponseBase) {
      const vatTuNhapKho = this.bienBanData.phieu_nhap_kho.vat_tu_nhap_kho.find(({id}) => id === vatTu.id);

      return this.formatCurrencyAndQuantity(+vatTuNhapKho.pivot.don_gia);
    },
    formatCurrencyAndQuantity,
    onClose() {
      this.$emit('on-close-modal');
    },
    onPrintingButtonClicked(): void {
      (this as any).$htmlToPaper('bien-ban-kiem-nghiem-printing-modal')
    },
  }
})
