
import { useRouter } from 'vue-router';
import { defineComponent, PropType } from 'vue';
import { useDateTime } from '@/core/hooks/use-date-time-hook';
import { IBienBanResponseBase, IPhieuNhapKhoResponseBase } from '@/core/interfaces/ApiResponses';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { useEnv } from "@/core/hooks/useEnv";
import { getErrorMsg, swalNotification } from '@/core/helpers/utils';
import { IHopDongResponseBase } from '@/core/interfaces/ApiResponses';
import { HopDongService } from '@/core/services/HopDong.service';
import { PhieuNhapKhoService } from '@/core/services/PhieuNhapKho.service';
import { useLoading } from '@/core/hooks/use-loading';

import BienBanKiemNghiemPrintModal from '@/components/shared/bien-ban-kiem-nghiem-print-modal/BienBanKiemNghiemPrintModal.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import ConfirmHoanThanh
  from "@/views/crafted/pages/bien-ban-giai-the/confirm-hoan-thanh-bien-ban-modal/ConfirmHoanThanh.vue";
import DivCol from '@/layout/_shared/DivCol.vue';
import DivRow from '@/layout/_shared/DivRow.vue';
import usePhieuNhap from '@/core/hooks/usePhieuNhap';

export default defineComponent({
  components: {
    ConfirmationDialog,
    BienBanKiemNghiemPrintModal,
    ButtonCustom,
    ConfirmHoanThanh,
    DivRow,
    DivCol,
  },

  name: 'phieu-nhap-kho-table',

  emits: ['on-click-detail', 'refresh'],

  setup() {
    const { moment } = useDateTime();

    const { push } = useRouterCustom();

    const { push: pushRouter } = useRouter();

    const { ButtonsType, ButtonTypeColors } = useButtonCustom();

    const { showHoanThanhPhieuNhap } = useEnv();

    const { formatDisplayingDate } = useDateTime();

    const { startLoading, endLoading } = useLoading();

    const { editable, goToEditPhieuNhap } = usePhieuNhap();

    return {
      ButtonsType, ButtonTypeColors,
      formatDisplayingDate,
      moment,
      push, pushRouter,
      showHoanThanhPhieuNhap,
      startLoading, endLoading,
      editable, goToEditPhieuNhap,
    }
  },

  props: {
    items: {
      type: Array as PropType<Array<IBienBanResponseBase>>,
      default: () => ([]),
    },
    idHopDong: {
      type: Number,
      default: 0,
    },
    showDeleteButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showEditButton: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      deletingPhieuNhap: null as IPhieuNhapKhoResponseBase | null,
      hopDongDetail: null as IHopDongResponseBase | null,
      selectingPhieuNhap: null as IPhieuNhapKhoResponseBase | null,
      selectingBienBan: null as IBienBanResponseBase | null,
    };
  },

  computed: {
    actionButtonColWidth() {
      let defaultWidth = 100;

      if (this.showHoanThanhPhieuNhap) defaultWidth += 50;

      if (this.showDeleteButton) defaultWidth += 50;

      if (this.showEditButton) defaultWidth += 50;

      return defaultWidth;
    },
  },

  methods: {
    goToEditPage(bienBanItem: IBienBanResponseBase) {
      this.push(`/quan-ly/hop-dong/${this.idHopDong}/bien-ban/${bienBanItem.id}/phieu-nhap-kho/${bienBanItem.phieu_nhap_kho.id}/chinh-sua`);
    },

    async openBienBanPrintModal(idHopDong: number, index: number) {
      try {
        await this.startLoading();

        const { data: { data } } = await HopDongService.fetchContractDetail(idHopDong);

        this.hopDongDetail = data;

        this.selectingBienBan = this.items[index];

        await this.endLoading();
      } catch (error) {
        await this.endLoading();

        await swalNotification(
            getErrorMsg(error, 'Có lỗi xảy ra khi lấy thông tin hợp đồng'),
            'error',
        );
      }
    },

    closePrintModal() {
      this.hopDongDetail = null;

      this.selectingBienBan = null;
    },

    onClickPhieuNhapDetail(khoItem: IPhieuNhapKhoResponseBase) {
      this.$emit('on-click-detail', {
        phieuNhapKhoData: khoItem,
      });
    },

    confirmHoanThanh() {
      this.selectingPhieuNhap = null;

      this.$emit('refresh');
    },

    async deletePhieuNhap() {
      if (!this.deletingPhieuNhap) return;

      try {
        await PhieuNhapKhoService.delete(this.deletingPhieuNhap.id);

        this.deletingPhieuNhap = null;

        this.$emit('refresh');
      } catch (error) {
        this.deletingPhieuNhap = null;

        await swalNotification(
            getErrorMsg(error, 'Có lỗi xảy ra khi xoá phiếu nhập này'),
            'error',
        );
      }
    },
  }
})
