import { ApiResponse } from "./../interfaces/ApiResponses";
import { apiServiceInstance } from "@/core/helpers/utils";

export default class ToolService {
  static convertPriceToText(price: number): Promise<{
    data: ApiResponse<string>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/tool/currency-to-words?number=${price}`
    );
  }
}
