
import { defineComponent, PropType } from 'vue';
import { IBienBanResponseBase } from '../../../../../core/interfaces/ApiResponses';
import { useDateTime } from '../../../../../core/hooks/use-date-time-hook';

import DivRow from '../../../../../layout/_shared/DivRow.vue';
import DivCol from '../../../../../layout/_shared/DivCol.vue';
import ButtonCustom from '../../../../../components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
  name: 'bien-ban-kiem-nghiem-table',

  components: { DivRow, DivCol, ButtonCustom },

  emits: ['on-detail-button-click'],

  setup() {
    const { moment } = useDateTime();

    return {
      moment,
    }
  },

  props: {
    items: {
      type: Array as PropType<Array<IBienBanResponseBase>>,
      default: () => ([]),
    },
  },

  methods: {
    onDetailButtonCLicked(bienBanData: IBienBanResponseBase) {
      this.$emit('on-detail-button-click', {
        bienBan: bienBanData,
      })
    }
  }
})
