
import { defineComponent, PropType } from 'vue';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';
import { IVatTuHopDongResponseBase } from '@/core/interfaces/ApiResponses';
import useVatTu from '@/core/hooks/use-vat-tu';

export default defineComponent({
  name: 'hop-dong-vat-tu-table',

  props: {
    items: {
      type: Array as PropType<Array<IVatTuHopDongResponseBase>>,
    },
    isLoading: {
			type: Boolean,
			default: false,
		},
		remainImport: {
			type: Array as PropType<Array<{
				id_vat_tu: number;
				so_luong: number;
			}>>,
			default: () => [],
		}
  },

  setup() {
    const { isTextDangerColumn, isValidLabel } = useVatTu();

    return { isTextDangerColumn, isValidLabel };
  },

  data() {
    return {
      vatTuItems: this.items as IVatTuHopDongResponseBase[],
    }
  },

  watch: {
		items(value) {
			this.vatTuItems = value;
		},
	},

  methods: {
    formatCurrencyAndQuantity,

		calcRemaining(id: number) {
			const remainVatTuItem = this.remainImport.find(item => item.id_vat_tu === id);

			if (remainVatTuItem) return formatCurrencyAndQuantity(+remainVatTuItem.so_luong, true);

			return 0;
		}
  }
})
