import {computed} from "vue";

export const useEnv = () => {
  const showHoanThanhPhieuNhap = computed(() => process.env.VUE_APP_ALLOW_UPDATE_NGAY_HOAN_THANH_PHIEU_NHAP === 'true');

  const showHoanThanhPhieuXuat = computed(() => process.env.VUE_APP_ALLOW_UPDATE_NGAY_HOAN_THANH_PHIEU_XUAT === 'true');

  const showHoanThanhBBGT = computed(() => process.env.VUE_APP_ALLOW_UPDATE_NGAY_HOAN_THANH_BBGT === 'true');

  const allowUpdateTaiKhoanPhieuXuatKhac = computed(() => process.env.VUE_APP_ALLOW_UPDATE_TAI_KHOAN_PHIEU_XUAT_KHAC === 'true')

  return {
    allowUpdateTaiKhoanPhieuXuatKhac,
    showHoanThanhPhieuNhap,
    showHoanThanhPhieuXuat,
    showHoanThanhBBGT,
  }
}
