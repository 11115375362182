import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_button_custom = _resolveComponent("button-custom")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_DivCol = _resolveComponent("DivCol")!
  const _component_DivRow = _resolveComponent("DivRow")!

  return (_openBlock(), _createBlock(_component_DivRow, null, {
    default: _withCtx(() => [
      _createVNode(_component_DivCol, { cols: 12 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table, {
            "empty-text": "Chưa có biên bản",
            size: "small",
            "cell-style": {padding: '0', height: '15px'},
            border: true,
            data: _ctx.items
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: "STT",
                width: "60"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.$index + 1), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "Số biên bản",
                width: "100"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.so_bien_ban), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "Ngày biên bản",
                width: "130"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.moment(scope.row.ngay_bien_ban).format('DD-MM-YYYY')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "Phiếu nhập kho số",
                width: "140"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.phieu_nhap_kho?.so_phieu || '-'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                label: "Nhập vào kho",
                width: "150"
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.phieu_nhap_kho?.kho?.ten_kho ? scope.row.phieu_nhap_kho?.kho?.ten_kho : ''), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { label: "Ý kiến" }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(scope.row.y_kien), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { width: "70" }, {
                default: _withCtx((scope) => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_button_custom, {
                      "button-type": "INFO",
                      "color-type": "info",
                      onClick: ($event: any) => (_ctx.onDetailButtonCLicked(scope.row))
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}